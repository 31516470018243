import { useEffect } from "react";
import { useProducts } from "./useProducts";
import {
  CircularProgress,
  Container,
  Stack,
  Typography,
  useTheme,
  Button,
  Box,
  Grid,
  Link,
} from "@mui/material";
import { ErrorText } from "../ErrorText";
import ProductList from "./ProductList";

function ProductListContainer({ appearance }) {
  const {
    fetchCustomersWithProducts,
    customersWithProducts,
    loading,
    errorMessage,
  } = useProducts(appearance);

  const familyEmails = {
    Ikääntyneet: "mikko.ikivuo@nhg.fi",
    Terveyspalvelut: "hanna.sissala@nhg.fi, paivi.suutarinen@nhg.fi",
    Sosiaalipalvelut: "petteri.bjurstrom@nhg.fi, paivi.suutarinen@nhg.fi",
    "Suun terveys": "petteri.bjurstrom@nhg.fi, pyry.niemela@nhg.fi",
    "Muut palvelut": "ville.niukko@kuntamaisema.fi, mikko.ikivuo@nhg.fi",
    "Tiedolla johtaminen": "severi.niskanen@nhg.fi",
  };

  const targetProducts = {
    "Asumispalveluiden laatu- ja vaikuttavuusmittaristo": {
      family: "Ikääntyneet",
      link: null,
      userHasProduct: false,
    },
    "Ikääntyneiden asiakasohjauksen vertaiskehittäminen": {
      family: "Ikääntyneet",
      link: null,
      userHasProduct: false,
    },
    "Asumispalveluiden vertaiskehittäminen": {
      family: "Ikääntyneet",
      link: null,
      userHasProduct: false,
    },
    "Kirurgian vertaiskehittäminen": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "Kotihoidon laatu ja vaikuttavuusmittaristo": {
      family: "Ikääntyneet",
      link: null,
      userHasProduct: false,
    },
    "Kotihoidon resursointityökalu": {
      family: "Ikääntyneet",
      link: null,
      userHasProduct: false,
    },
    "Kotihoidon vertaiskehittäminen": {
      family: "Ikääntyneet",
      link: null,
      userHasProduct: false,
    },
    "Lasten- ja perheiden palveluiden vertaiskehittäminen": {
      family: "Sosiaalipalvelut",
      link: null,
      userHasProduct: false,
    },
    "Vammaispalveluiden vertaiskehittäminen": {
      family: "Sosiaalipalvelut",
      link: null,
      userHasProduct: false,
    },
    "Naistentautien ja synnytysten vertaiskehittäminen": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "Neurokirurgian vertaiskehittäminen": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "Osastotoiminnan vertaiskehittäminen": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "Päivystyksen vertaiskehittäminen": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "Suun terveydenhuollon benchmarking": {
      family: "Suun terveys",
      link: null,
      userHasProduct: false,
    },
    "Suun terveydenhuollon laatu- ja vaikuttavuusmittaristo": {
      family: "Suun terveys",
      link: null,
      userHasProduct: false,
    },
    "Syöpätautien vertaiskehittäminen - teemaraportit": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "TK-Forum": {
      family: "Terveyspalvelut",
      link: null,
      userHasProduct: false,
    },
    "Numerus: toiminnan ja talouden raportointi": {
      family: "Muut palvelut",
      link: "https://kuntamaisema.fi/palvelumme/toiminnan-ja-talouden-raportointi/",
      userHasProduct: false,
    },
    "Hyvinvoinnin ja terveyden raporointi": {
      family: "Muut palvelut",
      link: null,
      userHasProduct: false,
    },
    "Tukipalveluiden kustannusvertaiskehittäminen": {
      family: "Muut palvelut",
      link: null,
      userHasProduct: false,
    },
    Dashboardit: {
      family: "Tiedolla johtaminen",
      link: null,
      userHasProduct: false,
    },
    Tietoallastoteutukset: {
      family: "Tiedolla johtaminen",
      link: null,
      userHasProduct: false,
    },
    Rajapintaratkaisut: {
      family: "Tiedolla johtaminen",
      link: null,
      userHasProduct: false,
    },
  };

  const productNames = customersWithProducts.reduce((names, item) => {
    const newNames = item.products.map((product) => product.name);
    return names.concat(newNames);
  }, []);

  const hasTargetProduct = productNames.some((product) =>
    targetProducts.hasOwnProperty(product)
  );

  productNames.forEach((product) => {
    if (targetProducts.hasOwnProperty(product)) {
      targetProducts[product].userHasProduct = true;
    }
  });

  const groupedProducts = Object.keys(targetProducts)
    .filter((product) => !targetProducts[product].userHasProduct)
    .reduce((groups, product) => {
      const family = targetProducts[product].family;
      if (!groups[family]) {
        groups[family] = [];
      }
      groups[family].push(product);
      return groups;
    }, {});

  useEffect(() => {
    const email = localStorage.getItem("email");
    fetchCustomersWithProducts(email);
  }, [fetchCustomersWithProducts]);

  let content = undefined;

  if (loading) {
    content = <CircularProgress color="secondary" size={120} />;
  } else if (errorMessage !== undefined) {
    // Custom error message for "No data found" error
    if (errorMessage === "No data found") {
      content = <NoCustomerElement />;
    } else {
      content = <ErrorText errorMessage={errorMessage} />;
    }
  } else if (customersWithProducts.length === 0) {
    content = <NoCustomerElement />;
  } else {
    content = (
      <>
        <ProductList customersWithProducts={customersWithProducts} />
        {hasTargetProduct && (
          <div className="ad-banner">
            <Typography mb={2} fontFamily="AeonikPro-Bold" fontSize={29}>
              Laajenna näkemystäsi muiden vertaiskehittämispalveluiden avulla
            </Typography>
            <Grid container spacing={3}>
              {Object.entries(groupedProducts).map(
                ([family, products], index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ marginBottom: "1em" }}>
                      <Typography mb={1} variant="h6" color="secondary">
                        {family === "Suun terveys" ? (
                          <Link
                            href="https://nordichealthcaregroup.com/our-solutions/dental-health-solution/"
                            target="_blank"
                          >
                            {family}
                          </Link>
                        ) : (
                          family
                        )}
                      </Typography>
                      {products.map((product, index) => (
                        <Typography mb={0.8} key={index}>
                          {targetProducts[product].link ? (
                            <Link
                              href={targetProducts[product].link}
                              target="_blank"
                            >
                              {product}
                            </Link>
                          ) : (
                            product
                          )}
                        </Typography>
                      ))}
                    </div>
                    <Box mt={2} style={{ marginTop: "auto" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          familyEmails[family]
                            ? `mailto:${familyEmails[family]}?subject=Lisätietoja ${family} vertaiskehittämispalveluista&body=Hei!%0D%0A%0D%0ALuin verkkosivuiltanne, että tarjoatte erilaisia ${family} -vertaiskehittämispalveluita. Voisitteko olla minuun yhteydessä asiasta?`
                            : "https://nordichealthcaregroup.com/contact/"
                        }
                      >
                        Kysy lisätietoja
                      </Button>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </div>
        )}
      </>
    );
  }

  return (
    <Container>
      <Stack alignItems="center">{content}</Stack>
    </Container>
  );
}

export default ProductListContainer;

function NoCustomerElement() {
  const theme = useTheme();
  return (
    <Typography color={theme.palette.secondary.main}>
      No customer assigned. Please contact customer-support@nhg.fi
    </Typography>
  );
}
